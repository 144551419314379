document.addEventListener("DOMContentLoaded", function () {
  const burger = document.getElementById("burger");
  const menu = document.getElementById("dropdown-menu");

  burger.addEventListener("click", function (e) {
    if (menu.classList.contains("show-dropdown")) {
      menu.classList.remove("show-dropdown");
      setTimeout(() => {
        menu.style.display = "none";
      }, 300);
    } else {
      menu.style.display = "block";
      setTimeout(() => {
        menu.classList.add("show-dropdown");
      }, 10);
    }
    e.stopPropagation();
  });

  document.addEventListener("click", function (e) {
    if (!menu.contains(e.target) && e.target !== burger) {
      menu.classList.remove("show-dropdown");
      setTimeout(() => {
        menu.style.display = "none";
      }, 300);
    }
  });

  window.addEventListener("resize", function (e) {
    if (e.target.innerWidth > 991) {
      menu.style.display = "none";
      menu.classList.remove("show-dropdown");
    }
  });
});
